import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import useNotifications from '@/composables/useNotifications'
import { queryConfirmationPlanningData, updateConfirmationPlanning } from '@/@core/queries/confirmation-planning'
import i18n from '@/libs/i18n'
import useCommon from '@/views/organization/useCommon'
import realmConnection from '@/views/habit/realm'

export default function useConfirmationPlanningList() {
  const { showErrorMessage } = useNotifications()
  const { handleError } = useCommon()
  const refConfirmationPlanningTable = ref(null)
  const confirmationPlannings = ref([])
  const { getItem, ObjectId } = realmConnection()

  // Table Handlers
  const tableColumns = computed(() => {
    return [
      // {
      //   key: 'roleName.name',
      //   label: i18n.t('message.tableHeader.role'),
      //   sortable: true,
      // },
      {
        key: 'processCode',
        label: i18n.t('message.tableHeader.code'),
        sortable: true,
      },
      {
        key: 'processName.name',
        label: i18n.t('message.tableHeader.process'),
        sortable: true,
      },
      {
        key: 'evaluatedName.name',
        label: i18n.t('label.evaluated'),
        sortable: true,
      },
      {
        key: 'confirmationsPerYear',
        label: i18n.t('message.tableHeader.confirmationsPerYear'),
        sortable: true,
      },
      {
        key: 'Actions',
        label: i18n.t('message.tableHeader.actions'),
      },
    ]
  })
  const perPage = ref(10)
  const totalConfirmationPlannings = ref(0)
  const currentPage = ref(1)
  const searchQuery = ref('')
  const sortBy = ref('processCode')
  const isSortDirDesc = ref(false)
  const isLoading = ref(true)

  const processFilter = ref([]);
  const workerFilter = ref([]);
  // const roleFilter = ref([]);

  const refetchData = () => {
    if (refConfirmationPlanningTable.value) {
      refConfirmationPlanningTable.value.refresh()
    }
  }

  const fetchConfirmationPlannings = () => {
    isLoading.value = true
    const {
      // eslint-disable-next-line camelcase
      role, client = null,
    } = store.state?.userStore?.userData
    
    let input = {
      limit: perPage.value,
      total: currentPage.value === 1,
      offset: (currentPage.value * perPage.value) - perPage.value,
      search: searchQuery.value,
      sortBy: sortBy.value,
      sortOrder: isSortDirDesc.value ? -1 : 1,
      processes: processFilter.value,
      evaluated: workerFilter.value,
      // roles: roleFilter.value,
      client_id: (role !== 'admin') ? client.$oid : null,
    }
    store
      .dispatch('organization-confirmation-planning/fetchConfirmationPlannings', {
        query: queryConfirmationPlanningData,
        variables: {
          input,
        },
      })
      .then(response => {
        if (response.data.errors) throw new Error(response.data.errors[0].message)
        const {
          ConfirmationPlanningData: {
            confirmationPlannings: c,
            totalRecords: total,
          },
        } = response.data.data || {}

        const promises = c.map(async (item) => {
          // For each item in confirmationPlannings, look the process
          const process = await getItem({ collection: 'process', query: { _id: ObjectId(item.process) }})
          // Add the process code
          if (process) item.processCode = process.code || null
        })
        
        // Wait for all promises to resolve
        Promise.all(promises).then(() => {
          confirmationPlannings.value = c
          if (currentPage.value === 1) totalConfirmationPlannings.value = total
        })
      })
      .catch((error) => {
        console.log(error)
        handleError({ error, defaultMessage: i18n.t('message.err_confirmation_planning') })
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const deleteConfirmationPlanning = (id, callback) => {
    store
      .dispatch('organization-confirmation-planning/deleteConfirmationPlanning', {
        query: updateConfirmationPlanning,
        variables: { query: { _id: id }, data: { deleted: true } },
      })
      .then(() => {
        callback(true)
      })
      .catch(() => {
        callback(false)
        showErrorMessage(i18n.t('message.confirmation_planning_remove_error'))
      })
  }

  watch([currentPage, perPage], () => {
    fetchConfirmationPlannings()
  })

  watch([searchQuery, sortBy, isSortDirDesc], () => {
    currentPage.value = 1
    fetchConfirmationPlannings()
  })

  return {
    fetchConfirmationPlannings,
    tableColumns,
    perPage,
    currentPage,
    totalConfirmationPlannings,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refConfirmationPlanningTable,
    confirmationPlannings,
    refetchData,
    deleteConfirmationPlanning,
    processFilter,
    workerFilter,
    // roleFilter,
    isLoading,
  }
}
